import React from 'react';
import Navbar from '../components/Navbar';
import '../styles/NavBar.css';
import {LayoutProvider} from '../context/LayoutContext';

const Layout = ({ children }) => {
  return (
    <LayoutProvider>
      <div>
        <Navbar />
        <div className="content">
          {children}
        </div>
      </div>
    </LayoutProvider>
  );
}

export default Layout;