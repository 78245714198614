import React, { useState, useEffect } from "react";
import {HeroSection} from "./HeroSection";
import Features from "./Features";
import Mission from "./Mission";
import Questionnaires from "./Questionnaires";
import SupportBanner from "./Supporters";
import Footer from "../Footer";
import "../../styles/MainPage.css";
import ReactPlayer from 'react-player'

const MainPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="mainpage fade-in">
      <HeroSection />
      <div
        className="body"
        style={isMobile ? {} : { marginLeft: "10%", marginRight: "10%" }}
      >
        <Features />
        <Mission />
        {window.innerWidth > 1050 && <div style={{alignItems: "center", justifyContent: "center", display: "flex", marginBottom: "7%"}}>
          <div style={{flexDirection: "column"}}>
          <div style={{width: "52vw", height: "26.1vw", borderRadius: "15px", backgroundColor: "#484F59", alignItems: "center", justifyContent: "center", display: "flex"}}>
          <ReactPlayer  width="50.8vw" height="26vw" url='https://youtu.be/j4slzTwQJ7E' />
          </div>
          <div style={{alignItems: "center", justifyContent: "center", display: "flex", marginTop: "10px", color: "#484F59", fontStyle:"italic"}}>
          Certain features are currently in development
          </div>
          </div>
        </div>}
      </div>
      <Questionnaires />
      <SupportBanner />
      <Footer />
    </div>
  );
}

export default MainPage;
