import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { login, logout, setToken } from "./store/slices/authSlice";
import { getAuth, onIdTokenChanged } from "firebase/auth";

const AuthHandler = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (!user) {
        dispatch(logout());
        return;
      }
      try {
        const token = await user.getIdToken(true);
        dispatch(login());
        dispatch(setToken(token));
      } catch (error) {
        console.error("AuthHandler: Failed to fetch token:", error);
        dispatch(logout());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    const auth = getAuth();
    const minutes = 5;
    const interval = minutes * 60 * 100;
    const handle = setInterval(async () => {
      const user = auth.currentUser;
      if (user) {
        console.log("AuthHandler: Refreshing token...");
        await user.getIdToken(true);
      }
    }, interval);
    return () => clearInterval(handle);
  }, [])

  return null;
};


export default AuthHandler;
