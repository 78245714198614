import React from "react";
import "../../styles/ProgressBar.css";

function ProgressBar({ score, maxScore }) {
  const percentage = maxScore > 0 ? (score / maxScore) * 100 : 0;

  return (
    <div className="progressContainer">
      <div className="progressBarContainer">
        <div
          className="progressBar"
          style={{
            width: `${percentage}%`,
            backgroundColor: "#484F59",
          }}
        ></div>
      </div>
      <div className="score">{`${score}/${maxScore}`}</div>
    </div>
  );
}

export default ProgressBar;