import React from "react";
import "../styles/DoroChat.css";


const PrivacyPolicyPopup = ({handleClose}) => {
    return (
        <div style={{ backgroundColor: "#484f59" }}>
        <div className="terms-popup">
          <div className="terms-content">
            <div className="scroll-wrapper"></div>
            <div className="disclaimer-text-wrapper">
              <div style={{ color: "#728094" }}>
                <h2 style={{ fontSize: "28px" }}>PRIVACY POLICY</h2>
                <p>This privacy notice for <strong>Razroze, Inc.</strong> ("we," "us," or "our"), explains how we collect, store, use, and share your information when you interact with our services ("Services"), including when you:</p>
                <ul>
                  <li>Use our app, Doro, or any other app of ours that links to this privacy notice</li>
                  <li>Engage with us through sales, marketing, or events</li>
                </ul>

                <p><strong>Questions or concerns?</strong> This notice helps you understand your privacy rights and choices. If you disagree with our policies, please do not use our Services. For any questions or concerns, please contact us.</p>

                <h3>Summary of Key Points</h3>

                <p>This summary highlights key points from our privacy notice, but more details are available in the full document linked in each section or found in our table of contents.</p>
                <ul>
                  <li><strong>What personal information do we collect?</strong> We may collect personal information when you use our Services, based on your interactions, choices, and the features you use.</li>
                  <li><strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.</li>
                  <li><strong>Do we receive information from third parties?</strong> We do not receive information from third parties.</li>
                  <li><strong>How do we use your information?</strong> We use your information to provide, improve, and administer our Services, communicate with you, ensure security and fraud prevention, and comply with legal obligations. We process your information only with a valid legal basis.</li>
                  <li><strong>When and with whom do we share your information?</strong> We do not share your information with third parties.</li>
                  <li><strong>How do we protect your information?</strong> We use organizational and technical measures to protect your information, acknowledging that no system is entirely secure.</li>
                  <li><strong>How can you exercise your rights?</strong> Contact us to exercise your rights under data protection laws or delete your data on the profile page.</li>
                </ul>

                <h3>Table of Contents</h3>
                <ol>
                  <li>What Information Do We Collect?</li>
                  <li>How Do We Use Your Information?</li>
                  <li>When and With Whom Do We Share Your Information?</li>
                  <li>How Long Do We Keep Your Information?</li>
                  <li>How Do We Protect Your Information?</li>
                  <li>What Are Your Privacy Rights?</li>
                </ol>

                <h4>1. What Information Do We Collect?</h4>
                <ul>
                  <li><strong>Information You Provide:</strong> We collect information that you provide directly to us, such as when you create an account, engage with our services, or contact us directly.</li>
                </ul>

                <h4>2. How Do We Use Your Information?</h4>
                <ul>
                  <li><strong>Account Management:</strong> We use your information to manage your account, provide services, and respond to your inquiries.</li>
                  <li><strong>Feedback:</strong> We may use your information to request feedback and manage your interactions with our Services.</li>
                </ul>

                <h4>3. When and With Whom Do We Share Your Information?</h4>
                <p>We may need to share your personal information in the following situations:</p>
                <ul>
                  <li><strong>Business Transfers:</strong> Your information may be transferred during business negotiations, mergers, or acquisitions.</li>
                </ul>

                <h4>4. How Long Do We Keep Your Information?</h4>
                <p>We retain your personal information as long as necessary to fulfill the purposes outlined in this privacy notice unless a longer retention period is required or permitted by law.</p>

                <h4>5. How Do We Protect Your Information?</h4>
                <p>We implement security measures designed to protect your information from unauthorized access, use, or disclosure.</p>

                <h4>6. What Are Your Privacy Rights?</h4>
                <p>You can withdraw consent at any time, review, or change your information by contacting us. Additionally, you have the right to delete your profile and any specific data you have shared with us. By deleting your data, we will delete your information from our systems completely, ensuring your data privacy and control.</p>
                <button onClick={handleClose} style={{ marginBottom: "30px" }} className="terms-button">OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default PrivacyPolicyPopup;