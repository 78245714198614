if (!Object.hasOwn) {
  Object.defineProperty(Object, "hasOwn", {
    value: function (object, property) {
      return Object.prototype.hasOwnProperty.call(object, property);
    },
    configurable: true,
    enumerable: false,
    writable: true,
  });
}
