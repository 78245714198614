import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import sessionImg from "../../assets/currentImages/completeSession.png";
import resultsImg from "../../assets/currentImages/viewDetails.png";
import trackImg from "../../assets/currentImages/trackSessions.png";
import {
  feature1Styles,
  feature2Styles,
  feature3Styles,
  featureContainerStyles,
  featureBoxStyles,
  featureBoxImgStyles,
  mobileFeatureBoxStyles,
  mobileFeatureContainerStyles,
  mobileFeatureBoxImgStyles,
} from "../../styles/FeaturesStyles";
import { selectIsLoggedIn } from "../../store/slices/authSlice";
import { useSelector } from "react-redux";

function Features() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const features = [
    {
      title: "Reflect",
      description: "Unlock self-awareness through guided thought exploration",
      img: sessionImg,
      style: feature1Styles,
      link: isLoggedIn ? "/doro-test-start" : "/get-started",
      backgroundColor: '#DDF7F5',
    },
    {
      title: "Journal",
      description:
        "Capture insights with therapeutic journaling",
      img: trackImg,
      style: feature3Styles,
      backgroundColor: '#DFE7FD',
      link: isLoggedIn ? "/profile" : "/get-started",
    },
    {
      title: "Track",
      description:
        "Explore your mental health with validated tools",
      img: resultsImg,
      style: feature2Styles,
      link: "/resources",
      backgroundColor: '#FBDFF6', 
    },
  ];

  return (
    <div
      style={{
        ...featureContainerStyles,
        ...(isMobile ? mobileFeatureContainerStyles : {}),
      }}
    >
      {features.map((feature, index) => (
        <Link to={feature.link} key={index} style={{
            ...featureBoxStyles,
            ...feature.style,
            ...(isMobile ? mobileFeatureBoxStyles : {}),
            transition: 'transform 0.3s ease-in-out',
            transform: 'scale(1)',
          }}
          onMouseOver={e => e.currentTarget.style.transform = 'scale(1.04)'} // Scale up by 10%
          onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
          >
          <img
            src={feature.img}
            alt={feature.title}
            style={{
              ...featureBoxImgStyles,
              ...(isMobile ? mobileFeatureBoxImgStyles : {}),
            }}
          />
          <div>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontWeight: "700",
                fontSize: isMobile ? "1.1em" : "1.9em",
                fontFamily: "Karla",
              }}
            >
              {feature.title}
            </Typography>
            <Typography
              style={{
                fontSize: isMobile ? "0.9em" : "1.2vw",
                fontFamily: "Karla",
              }}
            >
              {feature.description}
            </Typography>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Features;
