import { Button } from '@mui/material';
import microphoneImage from '../../assets/icons/microphone.svg'
import PropTypes from 'prop-types';
import useRecorder from '../../hooks/useRecorder';
import { useContext, useEffect, useState } from 'react';
import { DoroChatContext } from '../../context/DoroChatContext';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import { LayoutContext } from '../../context/LayoutContext.js';
import '../../styles/DoroChat.css';
import {theme} from '../../layout/Theme';

const RecordButton = () => {

  const {isRecording, setIsRecording} = useRecorder();
  const {inputDisabled, setInputDisabled} = useContext(DoroChatContext);
  const {windowSize} = useWindowSizeListener();
  const [otherProps, setOtherProps] = useState({});
  const {themeMode} = useContext(LayoutContext);

  const startRecording = () =>
    setIsRecording(prev => !prev);

  const stopSendRecording = () => {
    setInputDisabled(true);
    setIsRecording(prev => !prev);
  };
  
  useEffect(() => {
    if (windowSize.deviceType === 'mobile') {
      setOtherProps({
        marginBottom: '0.5em',
        width: '60px',
        height: '40px',
      });
    } else {
      setOtherProps({
        height: '45px',
      });
    }
  }, [windowSize]);

  return (
    <Button
      className={`buttonStyle ${isRecording ? 'recording' : ''}`}
      variant='outlined'
      onClick={!isRecording ? startRecording : stopSendRecording}
      disabled={inputDisabled}
      sx={{
        ...otherProps,
        borderRadius: '15px',
        padding: '0.3em 1em !important',
        backgroundColor: themeMode==='dark' ? 'transparent' : theme.palette.scrollbarC,
        borderColor: themeMode==='dark' ? '#D4DCE9' : theme.palette.scrollbarC,
        '&:hover': {
          backgroundColor: '#646B76 !important',
          borderColor: '#D4DCE9',
        },
      }}
    >
      <img src={microphoneImage} alt='Click Me' style={{ width: '2.61em', height: '2.61em' }} />
    </Button>);
};

RecordButton.propTypes = {
  isRecording: PropTypes.bool,
  startRecording: PropTypes.func,
  stopSendRecording: PropTypes.func,
  inputDisabled: PropTypes.bool
};

export default RecordButton;