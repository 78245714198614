import { useEffect, useState } from 'react';
import axiosInstance from "../utils/axiosConfig";
import getFirebaseIdToken from '../Firebase/getFirebaseIdToken';
import { useNavigate } from "react-router-dom";


const useTermsAndConditions = () => {
  const navigate = useNavigate();
  const [showTerms, setShowTerms] = useState(true);

  useEffect(() => {
    const userInformation = localStorage.getItem("userInformation");
    if (userInformation) {
      const userInfo = JSON.parse(userInformation);
      if (userInfo.hasOwnProperty("disclaimer_accepted")) {
        setShowTerms(!userInfo.disclaimer_accepted);
      }
    }
  }, []);

  const handleAgree = async () => {
    const idToken = await getFirebaseIdToken();
    if (!idToken) {
      console.error("No user is currently authenticated.");
      navigate('/get-started');
      return;
    }
    try {  
      const response = await axiosInstance.put(
        "/users/me/accept-disclaimer",
        {},
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      );
      document.querySelector('.terms-popup').classList.add('fade-out');
      setTimeout(() => setShowTerms(false), 200);
    } catch (error) {
      console.error("Error accepting disclaimer:", error);
    }
  };

  return (showTerms, handleAgree);
};

export default useTermsAndConditions;