import React, {useState, createContext, useMemo, useEffect} from 'react';
import { useLocation } from "react-router-dom";

const LayoutContext = createContext(null); 

const LayoutProvider = ({children}) => {
  const location = useLocation();
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [themeMode, setthemeMode] = useState('dark');

  useEffect(() => {
    setIsChatStarted(false);
  }, [location.pathname]);

  const toggleTheme = () => {
    setthemeMode(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };
  
  const value = useMemo(() => {
    return {
      isChatStarted,
      setIsChatStarted,
      themeMode,
      toggleTheme,  
    };
  }, [
      isChatStarted,
      setIsChatStarted,
      themeMode,
      toggleTheme,  
  ]);

  return (
      <LayoutContext.Provider value={value}>
          {children}
      </LayoutContext.Provider>
  );
};

export {LayoutProvider, LayoutContext};
