import React from 'react';
import { Button } from '@mui/material';
import { theme } from '../../layout/Theme';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';

const StyledButton = ({sx, ...props }) => {
  const {windowSize} = useWindowSizeListener();
  return (
    <Button
      {...props}
      sx={{
        mt: '20px',
        backgroundColor: theme.palette.hotPink,
        color: theme.palette.white,
        fontFamily: theme.fontFamily.primary,
        fontWeight: theme.fontWeight.medium,
        fontSize: windowSize.deviceType === 'mobile' ? theme.fontSize.smaller : theme.fontSize.small,
        textTransform: 'none',
        borderRadius: '25px',
        width: windowSize.deviceType === 'mobile' ? '130px' : '150px',
        cursor: 'pointer',
        border: '2px solid',
        borderColor: theme.palette.hotPink,
        '&:hover': {
          backgroundColor: 'transparent',
          border: '2px solid',
          borderColor: theme.palette.hotPink,
          boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
        },
        ...sx,
      }}
    >
      {props.children}
    </Button>
  );
};

export {StyledButton};