import { Button } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import { DoroChatContext } from '../../context/DoroChatContext';
import {theme} from '../../layout/Theme';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import { LayoutContext } from '../../context/LayoutContext';
import '../../styles/DoroChat.css';

const StartButton = () => {
  const {isChatStarted, setIsChatStarted, themeMode} = useContext(LayoutContext);
  const {remainingSessions, inputDisabled, handleOnClickSend} = useContext(DoroChatContext);
  const {windowSize} = useWindowSizeListener();
  const [otherProps, setOtherProps] = useState({});


  const handleOnClick = () => {
    if (isChatStarted) {
      handleOnClickSend();
    } else if (remainingSessions > 0) {
       setIsChatStarted(true);
    }
  }

  useEffect(() => {
    if (isChatStarted) {
      if (windowSize.deviceType === 'large') {
        setOtherProps({
          height: '45px',
        });
      } else if (windowSize.deviceType === 'tablet') {
        setOtherProps({
          fontSize: '12px',
          height: '45px',
        });
      } else {
        setOtherProps({
          width: '60px',
          height: '40px',
          fontSize: '12px',
          padding: '0.3em 1em',
        });
      }
    }
  },[windowSize, isChatStarted]);

  return (
    <Button
      className='buttonStyle'
      variant='outlined'
      onClick={handleOnClick}
      disabled={inputDisabled}
      sx={{
        ...otherProps,
        borderRadius: '15px',
        color: `${themeMode==='dark' ? theme.palette.scrollbarC : theme.palette.lightGray} !important`,
        backgroundColor: `${themeMode==='dark' ? theme.palette.lightGray : theme.palette.scrollbarC} !important`,
        borderColor: `${themeMode==='dark' ? theme.palette.lightGray : theme.palette.scrollbarC} !important`,
        animation: isChatStarted? '' : 'pulsate 1.5s ease-out infinite',
        '&:hover': {
            backgroundColor: 'transparent !important',
            color: `${themeMode==='dark' ? theme.palette.darkGray : theme.palette.scrollbarC} !important`,
        },
        '&:active': {
            backgroundColor: 'transparent !important',
            color: `${themeMode==='dark' ? theme.palette.darkGray : theme.palette.scrollbarC} !important`,
        },
        ml: isChatStarted && windowSize.deviceType != 'mobile' ? '1em' : 0,
        mr: isChatStarted && windowSize.deviceType === 'large'? '1em' : 0,
      }}
    >
      {isChatStarted ? 'Send' : 'Start'}
    </Button>
  );
};

export default StartButton;