
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { DoroChatContext } from '../../context/DoroChatContext';
import {TextField, styled} from '@mui/material';
import {theme} from '../../layout/Theme.js';
import useWindowSizeListener from '../../hooks/useWindowSizeListener.js';
import { LayoutContext } from '../../context/LayoutContext.js';

const StyledTextField = styled(TextField)({
  fontFamily: theme.fontFamily.secondary,
  lineHeight: '1.5em',
  width: '80%',
  height: 'auto',
  resize: 'none',
  boxSizing: 'border-box',
  overflowY: 'auto',
  zIndex: 1000,
  scrollbarColor: `${theme.palette.scrollbarC} transparent`, // Thumb and track color
});

const ChatTextInput = () => {
  const {isChatStarted, themeMode} = useContext(LayoutContext);
  const {inputDisabled, setInputDisabled, sendMessage, textBoxRef} = useContext(DoroChatContext);
  const {windowSize} = useWindowSizeListener();
  const [chatFontSize, setChatFontSize] = useState('16px');

  const handleOnKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const value = e.target.value;
      if (value && value !== '') {
        sendMessage(value, 'text');
        e.target.value = '';
        setInputDisabled(prev => !prev);
      }
    }
  };

  useEffect(() => {
    (windowSize.deviceType === 'mobile') ?
      setChatFontSize('14px') : setChatFontSize('16px');
    
  }, [windowSize, isChatStarted]);

  useEffect(() => {
    if (!inputDisabled && isChatStarted && textBoxRef.current) {
      textBoxRef.current.focus();
    }
  }, [inputDisabled, isChatStarted]);

  return (
    <StyledTextField
      sx={{
        '& .MuiOutlinedInput-root': {
          fontSize: chatFontSize,
        },
        '& .MuiOutlinedInput-root': {
          paddingRight: '8px',
          borderRadius: '15px',
          paddingTop: '0.75em',
          paddingBottom: '0.75em',
          backgroundColor: themeMode==='dark' ? theme.palette.white16 : 'transparent',
          color: themeMode==='dark' ? theme.palette.white : theme.palette.scrollbarC,
          '& fieldset': {
            borderColor: themeMode==='dark' ? theme.palette.white50 : theme.palette.scrollbarC,
          },
          '&:hover fieldset': {
            borderColor: themeMode==='dark' ? theme.palette.white50 : theme.palette.scrollbarC,
          },
          '&.Mui-focused fieldset': {
            borderColor: themeMode==='dark' ? theme.palette.white50 : theme.palette.scrollbarC,
          },
        },
      }}
      key='chat-text-input'
      variant='outlined'
      multiline
      maxRows={8}
      disabled={inputDisabled || !isChatStarted}
      placeholder="Type a message..."
      onKeyDown={handleOnKeyDown}
      inputRef = {textBoxRef}
      autoFocus = {true}
    />);
};

ChatTextInput.propTypes = {
  handleTextareaInput: PropTypes.func,
}


export default ChatTextInput;