import {React, useContext} from 'react';
import { Box, Typography, CircularProgress } from "@mui/material";
import { theme } from '../../layout/Theme';
import { LayoutContext } from '../../context/LayoutContext.js';

function CircularProgressWithLabel({ value, progress }) {
    const remainingTimeInMinutes = Math.ceil(value / 60); // Convert seconds to minutes
    const {themeMode} = useContext(LayoutContext);

    return (
        <Box
            sx={{
                position: "relative",
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    display: "inline-flex",
                    width: 100,
                    height: 65,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress
                    variant="determinate"
                    value={progress}
                    size={63}
                    thickness={2.6}
                    sx={{
                        color: themeMode==='dark' ? theme.palette.white80 : theme.palette.scrollbarC,
                        position: "absolute",
                        "& .MuiCircularProgress-circle": {
                            strokeLinecap: "round",
                        },
                    }}
                />
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: "0.6rem",
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ textShadow: "none !important", lineHeight: "1.1", fontSize: "1.2rem", fontFamily: "Karla", color: themeMode==='dark' ? theme.palette.white : theme.palette.scrollbarC}}
                    >
                        {remainingTimeInMinutes}
                    </Typography>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ textShadow: "none !important", lineHeight: "1", fontSize: "0.52rem", color: themeMode==='dark' ? theme.palette.white : theme.palette.scrollbarC}}
                    >
                        MINUTES
                    </Typography>
                </Box>
            </Box>
            <Typography
                variant="caption"
                component="div"
                sx={{ textShadow: "none !important", lineHeight: "1", fontSize: "0.6rem", marginTop: '0.3rem', fontWeight: 'bold', letterSpacing: '0.6px', color: themeMode==='dark' ? theme.palette.white : theme.palette.scrollbarC}}
            >
                Duration
            </Typography>
        </Box>
    );
}

export default CircularProgressWithLabel;
