import React, { useContext } from 'react';
import { Button, Stack} from '@mui/material';
import Brightness7Icon from '@material-ui/icons/Brightness7'; 
import Brightness4Icon from '@material-ui/icons/Brightness3';
import { LayoutContext } from '../../context/LayoutContext.js';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import '../../styles/DoroChat.css';

const ThemeSwitch = () => {
  const { themeMode, toggleTheme } = useContext(LayoutContext);
  const {windowSize} = useWindowSizeListener();

  return (
    <Stack direction= 'column'>
        <Button
        onClick={toggleTheme}
        className='buttonStyle'
        sx={{
            position: 'absolute',
            right:  windowSize.deviceType != 'mobile' ? '3.4%' : '4.2%',
            top: windowSize.deviceType != 'mobile' ? '11.5%' : '10%',
            color: themeMode === 'dark' ? '#FFFFFF' : '#484F59', 
            fontSize: '14em',
            backgroundColor: 'transparent',
            transform: windowSize.deviceType != 'mobile' ? 'scale(1.3)' : 'scale(0.9)',
            border: themeMode === 'dark' ? '1px solid #FFFFFF !important' : '1px solid #484F59 !important',
            borderRadius: '15px',
            zIndex: 3000,
            '&:hover, &:active': {
            backgroundColor: 'transparent',
            border: themeMode === 'dark' ? '1px solid #FFFFFF80 !important' : '1px solid #484F5980 !important',
            color: themeMode === 'dark' ? '#FFFFFF80' : '#484F5980',
            }
        }}
        >
            {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </Button>
    </Stack>
  );
};

export default ThemeSwitch;