// BANNERS
import AnxietyBanner from "../assets/bannerImgs/Anxiety.svg";
import HyperactivityBanner from "../assets/bannerImgs/Hyperactivity.svg";
import BipolarBanner from "../assets/bannerImgs/Bipolar.svg";
import SleepingBanner from "../assets/bannerImgs/Sleeping.svg";
import EatingBanner from "../assets/bannerImgs/Eating.svg";
import GamblingBanner from "../assets/bannerImgs/Gambling.svg";
import DepressionBanner from "../assets/bannerImgs/Depression.svg";
import ObsessionBanner from "../assets/bannerImgs/Obsession.svg";
import TraumaBanner from "../assets/bannerImgs/Trauma.svg";
import PsychoticBanner from "../assets/bannerImgs/psychotic.svg";
import AutismBanner from "../assets/bannerImgs/Autism.svg";
import PersonalityBanner from "../assets/bannerImgs/Personality.svg";

// LINE DECOR
import line1 from "../assets/linesResources/line1.svg";
import line2 from "../assets/linesResources/line2.svg";
import line3 from "../assets/linesResources/line3.svg";
import line4 from "../assets/linesResources/line4.svg";
import line5 from "../assets/linesResources/line5.svg";
import line6 from "../assets/linesResources/line6.svg";
import line7 from "../assets/linesResources/line7.svg";
import line8 from "../assets/linesResources/line8.svg";
import line9 from "../assets/linesResources/line9.svg";
import line10 from "../assets/linesResources/line10.svg";
import line11 from "../assets/linesResources/line11.svg";
import line12 from "../assets/linesResources/line12.svg";

const formatCategories = (data) => {
  const categoryMap = {
    Anxiety: {
      tag: "Anxiety",
      bannerSrc: AnxietyBanner,
      lineDecorImg: line1,
    },
    Depression: {
      tag: "Depression",
      bannerSrc: DepressionBanner,
      lineDecorImg: line2,
    },
    "Attention-Deficit and Hyperactivity": {
      tag: "Attention-Deficit and Hyperactivity",
      bannerSrc: HyperactivityBanner,
      lineDecorImg: line3,
    },
    Autism: {
      tag: "Autism",
      bannerSrc: AutismBanner,
      lineDecorImg: line4,
    },
    "Bipolar and Mood": {
      tag: "Bipolar and Mood",
      bannerSrc: BipolarBanner,
      lineDecorImg: line5,
    },
    Sleeping: {
      tag: "Sleeping",
      bannerSrc: SleepingBanner,
      lineDecorImg: line6,
    },
    "Trauma and Stress": {
      tag: "Trauma and Stress",
      bannerSrc: TraumaBanner,
      lineDecorImg: line7,
    },
    Personality: {
      tag: "Personality",
      bannerSrc: PersonalityBanner,
      lineDecorImg: line8,
    },
    "Preoccupation and Obsession": {
      tag: "Preoccupation and Obsession",
      bannerSrc: ObsessionBanner,
      lineDecorImg: line9,
    },
    Psychotic: {
      tag: "Psychosis and Dissociation",
      bannerSrc: PsychoticBanner,
      lineDecorImg: line10,
    },
    Eating: {
      tag: "Eating",
      bannerSrc: EatingBanner,
      lineDecorImg: line11,
    },
    "Substance Abuse and Gambling": {
      tag: "Substance Abuse and Gambling",
      bannerSrc: GamblingBanner,
      lineDecorImg: line12,
    },
  };

  return Object.entries(categoryMap).map(([label, props]) => ({
    bannerLabel: label,
    bannerSrc: props.bannerSrc,
    lineDecorImg: props.lineDecorImg,
    assessments: data.filter((assessment) =>
      assessment.tags.includes(props.tag)
    ),
  }));
};

export { formatCategories };
