import { useEffect, useState } from "react";

const getDeviceType = width => {
  if (width < 768) {
    return "mobile";
  } else if (width >= 768 && width < 1024) {
    return "tablet";
  } else {
    return "large";
  }
};

const useWindowSizeListener = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    deviceType: getDeviceType(window.innerWidth),
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      deviceType: getDeviceType(window.innerWidth),
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return {windowSize};

};

export default useWindowSizeListener;