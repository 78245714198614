import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import velocityImage from "../../assets/velocity.png";
import uwaterlooImage from "../../assets/uwaterloo.png";
import innovationImage from "../../assets/innovation.png";

function SupportBanner() {
  return (
    <Box sx={{ textAlign: 'center', marginBottom: '1%'}}>
      <Typography variant="subtitle1" sx={{ fontWeight: '800', fontSize: '18px', marginBottom: '8px', color: '#484F5980'}}>
        Supported By
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={6} sm={4} md={2}>
          <img src={velocityImage} alt="Velocity" style={{ maxWidth: '70%', height: 'auto' }} />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <img src={uwaterlooImage} alt="University of Waterloo" style={{ maxWidth: '80%', height: 'auto' }} />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <img src={innovationImage} alt="Innovation Factory" style={{ maxWidth: '80%', height: 'auto' }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SupportBanner;
