import React, { useState, useEffect, useContext } from 'react';
import {Box, Stack} from '@mui/material';
import StartButton from './StartButton.js';
import RecordButton from './RecordButton.js';
import ChatTextInput from './ChatTextInput.js';
import EndChatButton from './EndChatButton.js';
import useWindowSizeListener from '../../hooks/useWindowSizeListener.js';
import { DoroChatContext } from '../../context/DoroChatContext.js';
import { LayoutContext } from '../../context/LayoutContext.js';

const ChatBottomBar = () => {
  const {showTerms } = useContext(DoroChatContext);
  const {isChatStarted} = useContext(LayoutContext);
  const {windowSize} = useWindowSizeListener();
  const [stackWidth, setStackWidth] = useState(null);

  useEffect(() => {
    if (windowSize.deviceType === 'large') {
      setStackWidth('80%');
    } else {
      setStackWidth('90%');
    }
  }, [windowSize]);

  return (!showTerms &&
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}>
      <Stack 
        direction='row'
        id='input-area'
        sx={{
          width: stackWidth,
          alignItems: isChatStarted ? 'flex-end' : 'center',
          justifyContent: 'center',
          paddingBottom: '2em',
        }}
        >
          {isChatStarted && <ChatTextInput />}
          <Stack
            direction={windowSize.deviceType === 'mobile' ? 'column' : 'row'}
            justifyContent= {isChatStarted? 'space-between': 'center'}
            sx= {{
              pl: isChatStarted ? '1em' : 0,
            }}>
            {isChatStarted && <RecordButton/>}
            <StartButton/>
            {isChatStarted && windowSize.deviceType === 'large' && <EndChatButton/>}
          </Stack>
      </Stack>
    </Box>
  );
};

export default ChatBottomBar;