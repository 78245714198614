// ErrorPopup.js
import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ErrorPopup = ({ to, message }) => {
    return (
        <div className="terms-popup">
            <div className="error-content" style={{ margin: "0px 10px 0px 10px" }}>
                <p style={{ fontSize: "19px", fontWeight: "bold", paddingBottom: "10px", }}>
                    {message}
                </p>
                <Button
                    to={to}
                    component={Link}
                    sx={{
                        padding: "10px 10px",
                        borderRadius: "15px",
                        color: "#D4DCE9",
                        backgroundColor: "#728094",
                        "&:hover, &:active": {
                            backgroundColor: "#8A98AC !important",
                            color: "#CBD0DB !important",
                        },
                    }}
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

export default ErrorPopup;
