import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectIsLoggedIn } from "../../store/slices/authSlice";
import {
  questionnaireHeaderStyles,
  headerStyles,
  doroContainerStyles,
  viewMoreButtonStyles,
  viewButtonHover,
  mobileDoroContainerStyles,
} from "../../styles/QuestionnaireStyles";
import DoroCard from "../DoroCard";
import {
  featureContainerStyles,
  mobileFeatureContainerStyles,
} from "../../styles/FeaturesStyles";
import { formatCategories } from "../../utils/categoryUtils";
import "../../styles/testimonials.css";
import comma from "../../assets/comma.svg";


const Questionnaires = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [hover, setHover] = useState(false);
  const [viewHover, setViewHover] = useState(false);
  const [cardsData, setCardsData] = useState([]);

  const testimonials = [
    { id: 1, text: "Doro really helped me with **practical techniques for my procrastination**. It’s great to have such a supportive tool.", name: "Chris" },
    { id: 2, text: "Doro is **always there when I need it**, even at midnight. It's comforting to know I have support **whenever I need it**.", name: "Kirrithuna" },
    { id: 3, text: "I had a great overall experience with Doro! The **strategies I learned really helped me handle my anxiety** better.", name: "John" },
    { id: 4, text: "Doro has made it easier for me to handle my anxiety and stress. It’s **an affordable way to support my mental health**.", name: "Tabitha" },
  ];

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/ptests/");
        const data = response.data;
        const formattedCategories = formatCategories(data);
        localStorage.setItem("categories", JSON.stringify(formattedCategories));
        const formattedData = response.data.slice(0, 4).map((item) => ({
          title: item.abbreviation,
          description: item.summary,
          questions: `${item.statements} Questions`,
          time: item.duration.replace("minutes", "min"),
          id: item.id,
        }));
        setCardsData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div style={questionnaireHeaderStyles}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2 style={{ ...headerStyles, alignItems: "center", justifyContent: "center", textAlign: "center" }} elevation={2}>
            Questionnaires
            {!isMobile ? <p style={{ fontSize: "17px", fontWeight: "300", width: "100%", margin: "0 auto", paddingTop: "15px" }}>
              Explore mental health questionnaires and discuss them with Doro
            </p> : <p></p>}
          </h2>

        </div>
      </div>
      <div
        className="doro-container"
        style={{
          ...doroContainerStyles,
          ...(isMobile ? mobileDoroContainerStyles : {}),
        }}
      >
        {cardsData.map(card => (
          <DoroCard
            key={`testimonial-card-${card.id}`}
            title={card.title}
            description={card.description}
            questions={card.questions}
            time={card.time}
            id={card.id}
          />
        ))}
      </div>
      <button
        style={{
          ...viewMoreButtonStyles,
          ...(viewHover ? viewButtonHover : {}),
          textTransform: "none",
        }}
        onMouseEnter={() => setViewHover(true)}
        onMouseLeave={() => setViewHover(false)}
        component={Link}
      >
        <Link
          to="/resources"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          View More
        </Link>
      </button>

      <div className="testimonials-wrapper">
        <div className="testimonials-container">
          {testimonials.concat(testimonials).map(({ id, text, name, img }, index) => (
            <div key={`testimonial-card-${index}`} className="testimonial-card">
              <img src={comma} alt="Comma" className="comma-icon" />
              <p className="testimonial-text">
                {text.split("**").map((part, index) => index % 2 === 1 ?
                  <b style={{ fontWeight: "800", fontSize: "20px" }}>{part}</b> : part)}
              </p>
              <div className="testimonial-profile">
                <span className="profile-name">{name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>


      <div
        style={{
          ...featureContainerStyles,
          ...(isMobile ? mobileFeatureContainerStyles : {
            marginLeft: "10%",
            marginRight: "10%",
          }),
        }}
      >
      </div>
    </div>
  );
};

export default Questionnaires;
