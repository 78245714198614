import React, { useState, useEffect } from 'react';
import {Box, Stack, Typography, styled, Button} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectIsLoggedIn } from '../../store/slices/authSlice';
import heroImage from '../../assets/currentImages/mainPage/heroImage.svg';
import {theme} from '../../layout/Theme';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import {StyledButton} from '../common/StyledButton.js';
import '../../styles/DoroChat.css';

const headerProps = {
  sx: {
    background: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    width: '100%',
    height: { xs: '80vh', sm: '80vh', md: '95vh'},
  },
};
const StyledHeaderTypography = styled(Typography)(() => ({
  fontWeight: theme.fontWeight.bold,
  color: theme.palette.white,
  fontFamily: theme.fontFamily.primary,
}));

const StyledTypography = styled(Typography) ({
  fontWeight: theme.fontWeight.medium,
  fontSize: 'inherit',
});

const MainPageInfoText = ({deviceType}) => (
  <Box sx={{ 
    color: theme.palette.white, 
    mt: '14px', 
  }}>
    <Typography
      sx={{
        fontSize: deviceType === 'mobile' ? theme.fontSize.smaller : theme.fontSize.larger,
      }}>
      Offering early support through{' '}
      <StyledTypography component='span'>self-reflection</StyledTypography>,{' '}
      <StyledTypography component='span'>therapeutic journaling</StyledTypography>, and{' '}
      <StyledTypography component='span'>skills learning</StyledTypography>.
    </Typography>
  </Box>
);

const HeroSection = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();
  const {windowSize} = useWindowSizeListener();
  const [headerFontSize, setHeaderFontSize] = useState('45px');

  const handleButtonClick = () => {
    if (isLoggedIn) {
      navigate("/doro-test-start");
    } else {
      navigate("/get-started");
    }
  };

  useEffect(() => {
    if (windowSize.deviceType === 'mobile') {
      setHeaderFontSize(theme.fontSize.medium);
    } else if (windowSize.deviceType === 'tablet') {
      setHeaderFontSize(theme.fontSize.xLarge);
    }
  }, [windowSize]);

  return (
    <Box
      {...headerProps}
    >
      <Stack direction='column' 
        sx={{
          position: 'relative',
          top: '43%',
          left: '10%',
          color: theme.palette.white,
          maxWidth: windowSize.deviceType === 'mobile' ? '80%' : '60%',
        }}>
        <StyledHeaderTypography
          fontSize={headerFontSize}>
          Your Daily Mental Health Coach
        </StyledHeaderTypography>
        <MainPageInfoText deviceType={windowSize.deviceType}/>
        <StyledButton
          sx ={{
            animation: isLoggedIn ? '' : 'pulsate 1.5s ease-out infinite',
          }}
          onClick={handleButtonClick}>
          Get Started
        </StyledButton>
      </Stack>
    </Box>
  );
}

export {HeroSection};
