import { getAuth, onAuthStateChanged } from 'firebase/auth';

const getFirebaseIdToken = async () => {
  const auth = getAuth();

  return new Promise((resolve, reject) => {
    //Cheking if the user is authenticated
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken(); 
          resolve(idToken);
        } catch (error) {
          console.error("Failed to get token:", error);
          reject(null);//If authenticated but invalid token use the callback
        }
      } else {
        console.error("No user is currently authenticated.");
        resolve(null);
      }
    });
  });
};

export default getFirebaseIdToken;
