const baseFeatureStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  margin: '10px 8px',
  marginRight: '0.75%',
  marginLeft: '0.75%',
  borderRadius: '12px',
  color: '#484F59',
  fontWeight: '700',
};

export const feature1Styles = {
  ...baseFeatureStyles,
  borderRadius: '15px',
  border: '1px solid #484F59',
  backgroundColor: 'transparent',
};

export const feature2Styles = {
  ...baseFeatureStyles,
  border: '1px solid #484F59',
  borderRadius: '15px',
  backgroundColor: 'transparent',
};

export const feature3Styles = {
  ...baseFeatureStyles,
  border: '1px solid #484F59',
  borderRadius: '15px',
  backgroundColor: 'transparent',
};

export const featureContainerStyles = {
  display: "flex",
  justifyContent: "center",
  padding: "50px 0px",
  marginLeft: "0%",
  marginRight: "0%",
};

export const featureBoxStyles = {
  width: '30%',
  backgroundColor: '#fff',
  boxShadow: '0 3px 6px rgba(0, 0, 0, 0)',
  textAlign: 'center',
  margin: '0 20px',
  textDecoration: 'none',
};

export const featureBoxImgStyles = {
  width: '50%',
  marginBottom: '20px',
};

export const mobileFeatureBoxStyles = {
  flexDirection: "row",
  alignItems: "center",
  textAlign: "left",
  width: "auto",
};

export const mobileFeatureContainerStyles = {
  flexDirection: "column",
  alignItems: "center",
  marginLeft: "5%",
  marginRight: "5%",
};

export const mobileFeatureBoxImgStyles = {
  width: "40%",
  marginBottom: "10px",
};
