import React from "react";
import { TimeSeries, TimeRange, Index } from "pondjs";
import {
    ChartContainer, ChartRow, Charts, YAxis, LineChart, styler, ScatterChart, Legend
} from "react-timeseries-charts";

class TestResultsChart extends React.Component {
    constructor(props) {
        super(props);

        function formatData(data) {
            const result = {};
            data.forEach(item => {
                const name = item.ptest_name;
                const date = new Date(item.timestamp).toISOString();
                const normalizedScore = item.score / item.max_score * 100;
                if (!result[name]) {
                    result[name] = [];
                }
                result[name].push([date, normalizedScore]);
            });

            Object.keys(result).forEach(key => {
                result[key].sort((a, b) => new Date(a[0]) - new Date(b[0]));
            });

            return result;
        }

        const data = formatData(props.data);

        this.test_abbreviations = {};
        props.data.forEach(item => {
            this.test_abbreviations[item.ptest_name] = item.ptest_abbreviation;
        });


        this.series = {};
        Object.keys(data).forEach(key => {
            this.series[key] = new TimeSeries({
                name: key,
                columns: ["index", "score"],
                points: data[key].map(([d, value]) => [
                    Index.getIndexString("1h", new Date(d)),
                    value
                ])
            });
        });

        function getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        this.styles = Object.keys(data).reduce((acc, key) => {
            acc[key] = styler([
                { key: "score", color: getRandomColor(), width: 2 }
            ]);
            return acc;
        }, {});
    }

    render() {
        // Create the range from the series dictionary
        const range = Object.keys(this.series).reduce((acc, key) => {
            const r = this.series[key].range();
            return new TimeRange(
                Math.min(acc.begin(), r.begin()),
                Math.max(acc.end(), r.end())
            );
        }, new TimeRange(new Date(2100, 0, 1), new Date(1900, 0, 1)));

        // Adjust the range for visualization
        range.end().setDate(range.end().getDate() + 1);
        range.begin().setDate(range.begin().getDate() - 1);

        return (
            <div>
                <div className="row" >
                    <ChartContainer timeRange={range}>
                        <ChartRow height="150">
                            <YAxis
                                id="score"
                                label="Score (%)"
                                min={0}
                                max={100}
                                width="100"
                                type="linear"
                            />
                            <Charts>
                                {Object.keys(this.series).map(key => (
                                    <ScatterChart
                                        key={key + "-scatter"}
                                        axis="score"
                                        series={this.series[key]}
                                        columns={["score"]}
                                        style={this.styles[key]}
                                    />
                                ))}
                                {Object.keys(this.series).map(key => (
                                    <LineChart
                                        key={key + "-line"}
                                        axis="score"
                                        series={this.series[key]}
                                        columns={["score"]}
                                        style={this.styles[key]}
                                        interpolation="curveBasis"
                                    />
                                ))}
                            </Charts>
                        </ChartRow>
                    </ChartContainer>
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {Object.keys(this.series).map(key => (
                            <Legend
                                type="line"
                                align="right"
                                style={this.styles[key]}
                                categories={[
                                    {
                                        key: "score",
                                        label: this.test_abbreviations[key],
                                    }
                                ]}
                            />
                        ))}
                    </div>
                </div>

            </div>
        );

    }
}

export default TestResultsChart;
