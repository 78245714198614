import React from 'react';
import '../../styles/TermsPopup.css';

const TermsPopup = ({ showIntro, handleIntroFadeOut, handleAgree }) => {
    return (
        <div className="terms-popup">
            <div className="terms-content">
                <div className="scroll-wrapper">
                    {showIntro && (
                        <div className="info-text-wrapper">
                            <h2 style={{ fontSize: "24px", marginTop: "5px" }}>
                                A few points before we start...
                            </h2>
                            <ul style={{ fontSize: "18px", marginBottom: "30px" }}>
                                <li style={{ marginBottom: "12px" }}>
                                    Each session is <b>30 minutes</b>.
                                </li>
                                <li style={{ marginBottom: "12px" }}>
                                    Allocate at least <b>20-25 minutes</b> in a <b>quiet space</b>{" "}
                                    for your session with Doro for optimal outcomes.
                                </li>
                                <li style={{ marginBottom: "12px" }}>
                                    Sessions evolve to become more <b>conversational</b> and{" "}
                                    <b>tailored</b> to your needs.{" "}
                                </li>
                                <li style={{ marginBottom: "12px" }}>
                                    The <b>more sessions</b> you have, the <b>better Doro becomes</b> at
                                    understanding you and your needs. We suggest{" "}
                                    <b>having at least two sessions</b>, as the first session is
                                    primarily introductory.
                                </li>
                                <li style={{ marginBottom: "12px" }}>
                                    To send your messages, please click the <b>'Send'</b> button
                                    or press <b>'Enter'</b>.
                                </li>
                                <li style={{ marginBottom: "12px" }}>
                                    To record an audio message, click the <b>'Microphone'</b>{" "}
                                    button once to start recording and a second time to send it.
                                </li>
                                <li style={{ marginBottom: "12px" }}>
                                    To conclude your session, click the <b>'End'</b> button.
                                </li>
                                <li style={{ marginBottom: "12px" }}>
                                    Please allow <b>20-30 seconds</b> after your session ends for
                                    your summary and recommended questionnaires to be prepared.
                                </li>
                            </ul>
                            <button className="terms-button" onClick={handleIntroFadeOut}>
                                Proceed to Disclaimer
                            </button>
                        </div>
                    )}
                    <div className="disclaimer-text-wrapper">
                        {!showIntro && (
                            <div>
                                <h2 style={{ fontSize: "28px" }}>Disclaimer</h2>
                                <p style={{ fontSize: "19px", paddingRight: "20px" }}>
                                    Doro is designed to provide supportive dialogues and access to
                                    validated mental health questionnaires, fostering a proactive
                                    approach towards mental health awareness. However, it is not a
                                    substitute for professional medical advice, diagnosis, or
                                    treatment. The information and resources provided by Doro
                                    should not be used as a basis for making diagnostic or
                                    treatment decisions.
                                </p>
                                <p style={{ fontSize: "19px" }}>
                                    If you or someone you know are in crisis or think you may have
                                    an emergency, please use the emergency call button on this
                                    page. It's imperative to consult with a qualified healthcare
                                    provider or mental health professional for personalized advice
                                    and treatment.
                                </p>
                                <p style={{ fontSize: "19px" }}>
                                    Please note, the current version of Doro is intended for
                                    individuals 18 years of age or older.
                                </p>
                                <p style={{ fontSize: "19px" }}>
                                    At its core, Doro's sole aspiration is to pave the way for
                                    early intervention in psychological well-being, nurturing a
                                    journey towards self-awareness and proactive mental health
                                    care.
                                </p>
                                <button onClick={handleAgree} className="terms-button" style={{ marginBottom: "30px" }}>
                                    I AGREE
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsPopup;
