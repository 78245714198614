export const missionStyles = {
  padding: '50px 10px',
  textAlign: 'center',
  color: '#484F59',
};

export const missionTitleStyles = {
  fontSize: '32px',
  fontWeight: '800',
  marginBottom: '3%',
  lineHeight: '0.5',
};

export const missionContentStyles = {
  fontSize: '18px',
  margin: '0 auto',
  marginBottom: '3%',
  lineHeight: '2',
  textAlign: 'left',
};

export const missionButton = {
  backgroundColor: "#B8336A",
  color: "#fff",
  borderRadius: "25px",
  cursor: "pointer",
  padding: "10px 20px",
  border: "2px solid #B8336A",
  fontWeight: "bold",
  marginTop: "-1%",
  marginBottom: "3%",
};

export const missionButtonHover = {
  backgroundColor: "transparent",
  border: "2px solid #B8336A",
  boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
  color: "#B8336A",
  fontFamily: "Inter, sans-serif",
};
