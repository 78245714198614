import { useContext, useState, useEffect } from 'react';
import { Box, Typography, Fade, styled } from '@mui/material';
import comma from "../../assets/currentImages/white_comma.svg";
import darkComma from '../../assets/comma.svg';
import {theme} from '../../layout/Theme';
import '../../styles/testimonials.css';
import { DoroChatContext } from '../../context/DoroChatContext';
import useWindowSizeListener from '../../hooks/useWindowSizeListener';
import { quotes } from './quotes';
import '../../styles/DoroChat.css';
import { LayoutContext } from '../../context/LayoutContext';


const StyledQuoteTypography = styled(Typography)({
  fontSize: theme.fontSize.xLarge,
  color: theme.palette.white, //can't use a color with opacity otherwise Fade won't work
  textAlign: 'left',
  lineHeight: 1.6,
  fontFamily: theme.fontFamily.secondary,
  fontWeight: theme.fontWeight.large,
  marginLeft: '1em',
});

const StyledAuthorTypography = styled(Typography)({
  fontSize: theme.fontSize.large,
  color: theme.palette.white,
  textAlign: 'left',
  fontFamily: theme.fontFamily.secondary,
  fontWeight: theme.fontWeight.regular,
  fontStyle: 'italic',
  marginLeft: '1em',
});

const Quote = () => {
  const {showQuote} = useContext(DoroChatContext);
  const [quote, setQuote] = useState(null);
  const {windowSize} = useWindowSizeListener();
  const [quoteWidth, setQuoteWidth] = useState('60%');
  const {themeMode} = useContext(LayoutContext);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  }, []);

  useEffect(() => {
    if (windowSize.deviceType === 'mobile') {
      setQuoteWidth('90%');
    } else if (windowSize.deviceType === 'tablet') {
      setQuoteWidth('85%');
    } else {
      setQuoteWidth('60%');
    }
  });

  return (
    <Box 
      id='quote'  
      sx={{ alignSelf: 'flex-start', width: quoteWidth, justifySelf: 'top'}}>
      <Fade
        appear
        in={showQuote}
        timeout={{ enter: 700, exit: 700 }}>
        <Box>
          <img src={themeMode==='dark' ? comma : darkComma} alt='Comma' className='comma-icon' style={{marginBottom: '0.2vh', width: '15%'}}/>
          <StyledQuoteTypography style={{color: themeMode==='dark' ? theme.palette.white : theme.palette.scrollbarC, fontSize: windowSize.deviceType == 'mobile' ? theme.fontSize.large : theme.fontSize.xLarge}}>{quote?.quote}</StyledQuoteTypography>
          <StyledAuthorTypography style={{color: themeMode==='dark' ? theme.palette.white : theme.palette.scrollbarC, fontSize: windowSize.deviceType == 'mobile' ? theme.fontSize.smaller : theme.fontSize.large}}>{quote?.author}</StyledAuthorTypography>
        </Box>
      </Fade>
    </Box>
  );
};

export default Quote;