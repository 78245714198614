import React from "react";
import './ConfirmationPopup.css';
import close from '../../assets/close.svg';

function ConfirmationPopup({ onClose, onDelete, isProfileDelete = false }) {
    const warningMessage = isProfileDelete
        ? "Your data and account will be deleted, and Doro will no longer recognize you."
        : "Your session and all related data will be permanently deleted and not retained by Doro.";

    return (
        <div className="confirmation-popup-overlay" onClick={(e) => e.target === e.currentTarget && onClose()}>
            <div className="confirmation-popup-content">
                <button className="close-button" onClick={onClose}>
                    <img src={close} alt="Close" />
                </button>
                <p className="confirmation-popup-title">
                    {isProfileDelete ? "Delete Account" : "Delete Session"}
                </p>
                <p className="confirmation-popup-warning">
                    {warningMessage}
                </p>
                <div className="confirmation-popup-buttons">
                    <button className="confirmation-button delete" onClick={onDelete}>
                        {isProfileDelete ? "Delete Account" : "Delete Session"}
                    </button>
                    <button className="confirmation-button cancel" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationPopup;
