import React, {useEffect, useRef, useState, createContext, useMemo, useContext} from 'react';
import axiosInstance from "../utils/axiosConfig";
import useWebSocket from "../hooks/useWebSocket";
import useTermsAndConditions from "../hooks/useTermsAndConditions";
import { LayoutContext } from "./LayoutContext";
import getFirebaseIdToken from '../Firebase/getFirebaseIdToken';
import { useNavigate } from "react-router-dom";

const DoroChatContext = createContext(null); 

const DoroChatProvider = ({children}) => {
    const navigate = useNavigate();
    const {isChatStarted, setIsChatStarted} = useContext(LayoutContext);
    const [token, setToken] = useState(null);
    const [messages, setMessages] = useState([]);
    const [remainingSessions, setRemainingSessions] = useState(null);
    const [inputDisabled, setInputDisabled] = useState(false);
    const chatContainerRef = useRef(null);
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
    const textBoxRef = useRef(null);
    const [showQuote, setShowQuote] = useState(false);

    useEffect(() => {
      const fetchUserData = async () => {
        const idToken = await getFirebaseIdToken();
    
        if (!idToken) {
          console.error("Failed to retrieve idToken or no user is authenticated.");
          navigate('/get-started');
          return;
        }
        else {
          setToken(idToken);
        }
  
        try {
          const response = await axiosInstance.get(`/users/me/remaining-sessions`, {
            headers: {
              Authorization: `${idToken}`,
            },
          });
          setRemainingSessions(response.data);
        } catch (error) {
          console.error('Failed to fetch remaining sessions:', error);
        }
      };
    
      fetchUserData();
    
    }, [navigate]);
  
    const { sendMessage, timer, progress, isConnectionError, sessionID } = useWebSocket(token, isChatStarted, setMessages, setInputDisabled);
    const {showTerms, handleAgree} = useTermsAndConditions();

    useEffect(() => {
      if (isChatStarted && 
        (!messages.length || (messages.length <= 1 && messages[messages.length - 1].id === "typing-indicator"))) {
        setShowQuote(true);
      } else {
        setShowQuote(false);
      }
    }, [messages, isChatStarted]);

    const handleOnClickSend = () => {
      sendMessage(textBoxRef.current.value, 'text');
      textBoxRef.current.value = '';
      setInputDisabled(prev => !prev);
    };
  
    const value = useMemo(() => {
      return {
        showQuote,
        chatContainerRef,
        remainingSessions,
        messages,
        sendMessage,
        timer,
        progress,
        isConnectionError,
        sessionID,
        inputDisabled,
        setInputDisabled,
        showTerms,
        handleAgree,
        showFeedbackPopup,
        setShowFeedbackPopup,
        handleOnClickSend,
        textBoxRef,
      };
    }, [
      showQuote,
      chatContainerRef,
      remainingSessions,
      messages,
      sendMessage,
      timer,
      progress,
      isConnectionError,
      sessionID,
      inputDisabled,
      setInputDisabled,
      showTerms,
      handleAgree,
      showFeedbackPopup,
      setShowFeedbackPopup,
      handleOnClickSend,
      textBoxRef,
    ]);

    return (
        <DoroChatContext.Provider value={value}>
            {children}
        </DoroChatContext.Provider>
    );
};

export {DoroChatProvider, DoroChatContext};
