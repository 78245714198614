/* Main module for handling WebSocket connection and messages in the Dorochat component. */
import { useEffect, useRef, useCallback, useState } from 'react';
import { backendURI } from "../utils/axiosConfig";

export default function useWebSocket(token, isChatStarted, setMessages, setInputDisabled) {
    const websocket = useRef(null);
    const sessionEndedIntentionally = useRef(false);
    const sessionID = useRef(null);
    const totalSeconds = 30 * 60;   // 30 minutes
    const [progress, setProgress] = useState(0);
    const [timer, setTimer] = useState(0); // Initialize timer
    const errorTimeoutRef = useRef(null);
    const [isConnectionError, setConnectionError] = useState(false);

    const setConnectionErrorWithDelay = (isError) => {
        // show the pop up only after 5 seconds of error
        if (isError) {
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
            errorTimeoutRef.current = setTimeout(() => {
                setConnectionError(true);
            }, 5000);
        } else {
            if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
            setConnectionError(false);
        }
    };
    const sendMessage = useCallback((message, type = "text") => {
        if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
            console.error("WebSocket not connected. Cannot send message.");
            return;
        }

        websocket.current.send(JSON.stringify({ "m_type": type, "content": message }));
        if (message !== "" && type === "text") {
            setMessages((messages) => {
                return [
                    ...messages,
                    { id: Date.now(), text: message, role: "user" },
                    {
                        id: "typing-indicator",
                        text: "",
                        role: "assistant",
                        class: "typing-indicator",
                    },
                ];
            });
        } else if (message !== "" && type === "audio") {
            setMessages((messages) => {
                return [
                    ...messages,
                    {
                        id: "typing-indicator",
                        text: "",
                        role: "assistant",
                        class: "typing-indicator",
                    },
                ];
            });
        }
    }, [setMessages]);

    const receiveMessage = useCallback((event) => {
        const response = JSON.parse(event.data);
        setMessages((messages) => messages.filter((message) => message.id !== "typing-indicator"));
        if (response.role === "assistant") {
            setInputDisabled(false);
        }
        if (!sessionID.current) {
            sessionID.current = response.session_id;
            sendMessage("", "text");
            setInputDisabled(true);
            setMessages(() => [{ id: "typing-indicator", text: "", role: "assistant", class: "typing-indicator" }]);
            return;
        }

        setMessages((messages) => {
            if (messages.length === 0 || messages[messages.length - 1].role !== response.role) {
                return [
                    ...messages,
                    { id: Date.now(), text: response.content, role: response.role },
                    ...(response.role === "user" ? [{ id: "typing-indicator", text: "", role: "assistant", class: "typing-indicator" }] : [])
                ];
            } else {
                return messages.map((message, index) => {
                    if (index === messages.length - 1) {
                        return { ...message, text: message.text + response.content };
                    }
                    return message;
                });
            }
        });
    }, [sendMessage, setInputDisabled, setMessages]);

    useEffect(() => {
        if (!isChatStarted | sessionEndedIntentionally) return;
        if (websocket.current && (websocket.current.readyState === WebSocket.OPEN || websocket.current.readyState === WebSocket.CONNECTING)) {
            return;
        }

        if (!token) {
            console.error("No token available for authorization.");
            setConnectionErrorWithDelay(true);
            return;
        }
        console.log("Connecting to WebSocket...", websocket.current);
        websocket.current = new WebSocket(`wss://${backendURI}/chat/ws?token=${token}`);

        websocket.current.onopen = () => {
            sessionID.current = null;
            sessionEndedIntentionally.current = false;
            setConnectionErrorWithDelay(false);
        };

        websocket.current.onerror = (error) => {
            console.error("WebSocket Error: ", error);
        };

        websocket.current.onclose = (event) => {
            console.log("WebSocket closed with event: ", event);
            if (!sessionEndedIntentionally.current) {
                setConnectionErrorWithDelay(true);
            }
        };

        websocket.current.onmessage = receiveMessage;

        const closeWebSocket = (reason = "User left the session.") => {
            if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
                sessionEndedIntentionally.current = true;
                websocket.current.close(1000, reason);
            }
        };

        window.addEventListener("beforeunload", closeWebSocket);
        window.addEventListener("unload", closeWebSocket);

        return () => {
            window.removeEventListener("beforeunload", closeWebSocket);
            window.removeEventListener("unload", closeWebSocket);
            closeWebSocket("Session unmounted.");
        };

    }, [token, isChatStarted, receiveMessage, sendMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isChatStarted) return;
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => {
                const newTimer = prevTimer + 1;
                if (newTimer >= totalSeconds) {
                    clearInterval(intervalId);
                    setInputDisabled(true);
                    if (websocket.current) {
                        sessionEndedIntentionally.current = true;
                        websocket.current.close(1000, "Session ended.");
                    }
                    return totalSeconds;
                }
                return newTimer;
            });
        }, 1000); // Update every minute

        // Calculate progress
        setProgress((timer / totalSeconds) * 100);
        return () => clearInterval(intervalId);
    }, [timer, isChatStarted, totalSeconds, setInputDisabled]);

    return { sendMessage, timer, progress, isConnectionError, sessionID };
}
