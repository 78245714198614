import React, { useState, useEffect, useRef } from "react";
import {Stack} from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axiosInstance from "../../utils/axiosConfig";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import ReactMarkdown from "react-markdown";
import DoroCard from "../DoroCard";
import "../../styles/ProfileBeforeSession.css";
import avatar from "../../assets/icons/avatar.png";
import SubscriptionPopUp from "./subscriptionPopUp";
import 'react-tabs/style/react-tabs.css';
import infinity from "../../assets/Group.png";
import TestResultsChart from "./TestResultsChart";
import { useCallback } from 'react';
import getFirebaseIdToken from '../../Firebase/getFirebaseIdToken';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmationPopup from "./ConfirmationPopup";
import { getAuth, signOut } from "firebase/auth";
import './ConfirmationPopup.css';


function ProfileBeforeSession() {
  const [_, setUser] = useState();
  const navigate = useNavigate();
  const [testResults, setTestResults] = useState([]);
  const [lastTestResults, setLastTestResults] = useState([]);
  const [sessionSummary, setSessionSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cardsData, setCardsData] = useState([]);
  const testResultsBoxStyles =
    testResults.length === 0 ? "test-results fixed-height" : "test-results";
  const intervalRef = useRef();
  const [selectedSummaryIndex, setSelectedSummaryIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [remainingSessions, setRemainingSessions] = useState(null);
  const [showSessionConfirmationPopup, setShowSessionConfirmationPopup] = useState(false);
  const [sessionToDelete, setSessionToDelete] = useState(null);
  const [showAccountConfirmationPopup, setShowAccountConfirmationPopup] = useState(false);



  const handleDelete = () => {
    if (sessionToDelete) {
      deleteSession(sessionToDelete);
    }
    setShowSessionConfirmationPopup(false);
  };

  const openAccountConfirmationPopup = () => {
    setShowAccountConfirmationPopup(true);
  };  

  const openSessionConfirmationPopup = (sessionId) => {
    setSessionToDelete(sessionId);
    setShowSessionConfirmationPopup(true);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const [sessionSummaries, setSessionSummaries] = useState([
    {
      "date": "last session"
    }
  ]);

  const scrollLeft = () => {
    const tabList = document.querySelector('.tab-list-scrollable-container');
    tabList.scrollBy({ left: -200, behavior: 'smooth' });  // Adjust scroll amount as needed
  };

  const scrollRight = () => {
    const tabList = document.querySelector('.tab-list-scrollable-container');
    tabList.scrollBy({ left: 200, behavior: 'smooth' });  // Adjust scroll amount as needed
  };


  const addLastSessionSummary = (lastSession) => {
    setSessionSummaries(prevSummaries => [
      lastSession,
      ...prevSummaries.slice(1),
    ]);
    setSessionSummary(lastSession);
  };

  const handleSummarySelect = (index) => {
    setSelectedSummaryIndex(index);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const idToken = await getFirebaseIdToken();

      if (!idToken) {
        console.error("No user is currently authenticated.");
        navigate('/get-started');
        return;
      }

      try {
        console.log("Fetched idToken from Firebase");

        const cachedCategories = localStorage.getItem("categories");

        if (cachedCategories) {
          const categories = JSON.parse(cachedCategories);
          const assessmentsMap = categories.reduce((acc, category) => {
            category.assessments.forEach((assessment) => {
              acc[assessment.abbreviation] = assessment.max_score;
            });
            return acc;
          }, {});
          // Fetch user information
          const response = await axiosInstance.get(`/users/me/`, {
            headers: {
              Authorization: `${idToken}`,
            },
          });

          localStorage.setItem("userInformation", JSON.stringify(response.data));
          setUser(response.data.email);

          // Fetch remaining sessions
          const sessionData = await axiosInstance.get(`/users/me/remaining-sessions`, {
            headers: {
              Authorization: `${idToken}`,
            },
          });

          setRemainingSessions(sessionData.data);

          if (response.data.test_results && response.data.test_results.length > 0) {
            // Filter and keep the most recent test results by ptest_abbreviation
            const resultsWithMaxScore = Object.values(response.data.test_results).map(
              (test) => ({
                ...test,
                max_score: assessmentsMap[test.ptest_abbreviation] || null,
              })
            );
            setTestResults(resultsWithMaxScore);

            const filteredResults = resultsWithMaxScore.reduce((acc, current) => {
              const existing = acc[current.ptest_abbreviation];
              if (
                !existing ||
                new Date(existing.timestamp) < new Date(current.timestamp)
              ) {
                acc[current.ptest_abbreviation] = current; // Update with the most recent test
              }
              return acc;
            }, {});

            setLastTestResults(Object.values(filteredResults));
          }
        }
      } catch (error) {
        console.error("Error fetching user data or sessions:", error);
      }
    };

    fetchUserInfo();
  }, [navigate]);

  function shuffleAssessments(categories) {
    let allAssessments = categories.reduce(
      (acc, category) => acc.concat(category.assessments),
      []
    );

    for (let i = allAssessments.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [allAssessments[i], allAssessments[j]] = [
        allAssessments[j],
        allAssessments[i],
      ]; // ES6 destructuring swap
    }

    return allAssessments.slice(0, 4);
  }


  // Handle assessments data fetched from the response
  const handleAssessments = useCallback((sessionData) => {
    const recommendedPtests = [...new Set(sessionData.recommended_ptests)];
    const cachedCategories = localStorage.getItem("categories");

    if (cachedCategories) {
      const categories = JSON.parse(cachedCategories);
      const matchedAssessments = [];

      categories.forEach((category) => {
        category.assessments.forEach((assessment) => {
          if (recommendedPtests.includes(assessment.id)) {
            if (!matchedAssessments.some(a => a.id === assessment.id)) {
              matchedAssessments.push(assessment);
            }
          }
        });
      });

      if (matchedAssessments.length === 0) {
        matchedAssessments.push(...shuffleAssessments(categories));
      }

      setCardsData(matchedAssessments);
    }
  }, []);


  const startPollingForLastSession = useCallback(() => {
    clearInterval(intervalRef.current);  // Clear existing interval before setting a new one
    intervalRef.current = setInterval(async () => {
      const idToken = await getFirebaseIdToken();

      if (!idToken) {
        clearInterval(intervalRef.current);
        navigate('/get-started');
        return;
      }
      try {
        const response = await axiosInstance.get(`/users/me/last-session`, {
          headers: { Authorization: `${idToken}` }
        });
        if (response.status === 200) {
          axiosInstance
            .get(`/users/me/remaining-sessions`, {
              headers: {
                Authorization: `${idToken}`,
              },
            })
            .then((sessionCounts) => {
              setRemainingSessions(sessionCounts.data);
              addLastSessionSummary(response.data);
              setLoading(false);
              clearInterval(intervalRef.current);  // Stop polling once data is fetched
              handleAssessments(response.data);
            })
        }
      } catch (error) {
        console.error("Error fetching last session data:", error);
        if (error.response && error.response.status === 404) {
          clearInterval(intervalRef.current);  // Stop polling if session not found
          setLoading(false);
        }
      }
    }, 1000);
  }, [navigate, handleAssessments]);

  useEffect(() => {
    // Fetch prior sessions first
    const fetchPriorSessions = async () => {
      const idToken = await getFirebaseIdToken();

      if (!idToken) { 
        navigate('/get-started');
        return;
      }
  
      try {
        const response = await axiosInstance.get(`/users/me/prior-sessions`, {
          headers: { Authorization: `${idToken}` }
        });
        if (response.status === 200) {
          setSessionSummaries([{ "date": "last session" }, ...response.data]);
          // Start polling only after successfully fetching prior sessions
          startPollingForLastSession();
        }
      } catch (error) {
        console.error("Error fetching prior sessions:", error);
        setLoading(false);
      }
    };

    fetchPriorSessions();

    // Clean up on unmount
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [navigate, startPollingForLastSession]);

  const deleteSession = async (sessionId) => {
    const idToken = await getFirebaseIdToken();
  
    if (!idToken) {
      navigate('/get-started');
      return;
    }
  
    try {
      const response = await axiosInstance.delete(`/users/me/delete-session/${sessionId}`, {
        headers: { Authorization: `${idToken}` }
      });
      
      if (response.status === 200) {
        setSessionSummaries(prevSummaries => prevSummaries.filter(session => session.id !== sessionId));
        console.log(`Session ${sessionId} deleted successfully.`);

        const response = await axiosInstance.get(`/users/me/prior-sessions`, {
          headers: { Authorization: `${idToken}` }
        });

        if (response.status === 200) {
          setSessionSummaries([{ "date": "last session" }, ...response.data]);
          handleSummarySelect();
          startPollingForLastSession();
        }
      }
    } catch (error) {
      console.error(`Error deleting session ${sessionId}:`, error);
    }
  };

  const deleteAccount = async () => {
    const idToken = await getFirebaseIdToken();
  
    if (!idToken) {
      navigate('/get-started');
      return;
    }
  
    try {
      const response = await axiosInstance.delete(`/users/me/delete-account`, {
        headers: { Authorization: `${idToken}` },
      });
  
      if (response.status === 200) {
        const auth = getAuth();
        await signOut(auth);
        navigate('/');
        console.log("Account deleted successfully.");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };
  
  

  // This useEffect will be dedicated to cleaning up the interval when the component unmounts
  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <>
      <div className="profile-before-session fade-in">
        <div className="profile-section">
          <div className="user-info-background">
            <div className="user-info">
              <img src={avatar} alt="User Avatar" className="user-avatar" />
              <div style={{ color: "white", marginTop: "10px" }}>
                Remaining Sessions:
              </div>
              <div style={{ color: remainingSessions === null ? "transparent" : "white", fontWeight: "700", fontSize: "5vh", marginBottom: "8px"}}>
                {remainingSessions === null ? "0" : (remainingSessions > 100 ? "" : remainingSessions)}
              </div>
              {remainingSessions > 100 &&
                <img style={{ height: "30px", width: "60px", marginTop: "10px", marginBottom: "8px"}} src={infinity} alt="remaining sessions" />}
              {remainingSessions <= 100 && <button className="session-button purchase" onClick={togglePopup}>
                <Link
                  to=""
                  style={{ textDecoration: "none", color: "inherit", fontSize: window.innerWidth > 760 ? "16px" : "12px", fontWeight: "600"}}
                >
                  Purchase Sessions
                </Link>
              </button>}
            </div>
            <Stack className="session-info-container" direction='column'>
              <Tabs selectedIndex={selectedSummaryIndex !== null && selectedSummaryIndex >= 0 && selectedSummaryIndex < sessionSummaries.length ? selectedSummaryIndex : 0} onSelect={handleSummarySelect} selectedTabClassName="sessionSummarySelected">
                <Stack className="summaries-tabs-container" direction='row'>
                  <button onClick={() => scrollLeft()} className="session-tabs-button">&lt;</button>
                  <div className="tab-list-scrollable-container" style={{ zIndex: '6 !important' }}>
                    <TabList>
                      {sessionSummaries.map((session, index) => (
                        <Tab key={index}>{index === 0 ? "Last Session" : session.created_at.substring(0, 10)}</Tab>
                      ))}
                    </TabList>
                  </div>
                  <button onClick={() => scrollRight()} className="session-tabs-button">&gt;</button>
                </Stack>
                {sessionSummaries.map((session, index) => (
                  <TabPanel key={index} className="tabpanel">
                    <div className="session-button-box">
                      <div className="inner-scroll-container">
                        {index === 0 && loading ? (
                          <div className="spinner-wrapper">
                            <div className="session-indicator">Preparing the summary </div>
                          </div>
                        ) : (index === 0 && session.summary) || (index != 0) ? (
                          <div>
                          <ReactMarkdown className="summary">
                            {session.user_summary == null ? session.summary : session.user_summary}
                          </ReactMarkdown>
                          <button
                            className="delete-session-button"
                            style={{ position: 'absolute', bottom: '20px', right: '20px' }}
                            onClick={() => openSessionConfirmationPopup(session.id)}
                          >
                            <FontAwesomeIcon size="lg" icon={faTrash} />
                          </button>
                        </div>
                        ) : (
                          <button className="session-button" 
                                  to="/doro-test-start" 
                                  onClick={() => navigate("/doro-test-start")}
                                  style={{ marginTop: "15px", marginBottom: "15px" }}>
                            <Link
                              style={{ textDecoration: "none", color: "inherit" }}
                            >
                              START YOUR SESSION
                            </Link>
                          </button>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </Tabs>
              {showSessionConfirmationPopup && (
                <ConfirmationPopup
                  onClose={() => setShowSessionConfirmationPopup(false)}
                  onDelete={handleDelete}
                  isProfileDelete={false}
                />
              )}
              {showAccountConfirmationPopup && (
                <ConfirmationPopup
                  onClose={() => setShowAccountConfirmationPopup(false)}
                  onDelete={deleteAccount}
                  isProfileDelete={true}
                />
              )}
            </Stack>
          </div>

          <div className="assignment-container">
            <h2>Until our next chat...</h2>
            <div className="assignment-box">
              <div className="inner-scroll-container">
                {loading ? (
                  <div className="spinner-wrapper">
                    <div className="session-indicator">Preparing the summary </div>
                  </div>
                ) : sessionSummary && sessionSummary.assignment ? (
                  <ReactMarkdown className="summary">
                    {sessionSummary.assignment}
                  </ReactMarkdown>
                ) : (
                  <button className="session-button" 
                          style={{marginTop: "20px", marginBottom: "20px"}} 
                          onClick={() => navigate("/doro-test-start")}> 
                    <Link
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      START YOUR SESSION
                    </Link>
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="test-results-container">
            <h2>Results</h2>
            <div className={testResultsBoxStyles}>
              {lastTestResults.length === 0 ? (
                <button className="session-button">
                  <Link
                    to="/resources"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    TAKE MENTAL HEALTH QUESTIONNAIRES
                  </Link>
                </button>
              ) : (
                lastTestResults.map((test) => (
                  <div key={test.completed_test_id} className="test-result">
                    <div style={{ width: "140px", textAlign: "end", color: "#B8336A" }}>
                      <h3>{test.ptest_abbreviation}</h3>
                    </div>
                    <ProgressBar score={test.score} maxScore={test.max_score} />
                    <div className="test-date">
                      {"Date: " + test.timestamp.split(" ")[0]}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {
            window.innerWidth > 1030 &&
            <div className="test-results-container">
              <h2 style={{ marginTop: "0%" }}>Trends</h2>
              <div className="trend-results" style={{ paddingTop: testResults.length === 0 ? "5%" : "8%" }}>
                {testResults.length === 0 ? (
                  <button className="session-button">
                    <Link
                      to="/resources"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      TAKE MENTAL HEALTH QUESTIONNAIRES
                    </Link>
                  </button>
                ) : (
                  <TestResultsChart data={testResults} />
                )}
              </div>
            </div>
          }

          {
            loading ? (
              <>
                <h2 className="related-questionnaires-header">
                  Questionnaires
                </h2>
                <div className="spinner-wrapper" style={{ marginBottom: "7%", marginTop: "7%" }}>
                  <div className="loading-spinner"></div>
                </div>
              </>
            ) : cardsData.length > 0 ? (
              <div>
                <h2 className="related-questionnaires-header">
                  Questionnaires
                </h2>
                <div
                  className="doro-container"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px", /* This adds space between the cards */
                    justifyContent: "center", /* Aligns cards to the start of the container */
                    marginBottom: "2%",
                  }}
                >
                  {cardsData.map((card, index) => (
                    <DoroCard
                      key={index}
                      title={card.abbreviation}
                      description={card.summary}
                      questions={card.statements}
                      time={card.duration}
                      id={card.id}
                    />
                  ))}
                </div>
              </div>
            ) : null
          }
        </div >
        <SubscriptionPopUp isOpen={showPopup} closePopup={togglePopup}>
        </SubscriptionPopUp>
        <button className="delete-profile-button purchase" style={{ marginTop: "8px", marginBottom: "5%" }} onClick={openAccountConfirmationPopup}>
          <Link
            to=""
            style={{ textDecoration: "none", color: "inherit", fontWeight: "600"}}
          >
            Delete Account
          </Link>
        </button>
      </div >
      <Footer />
    </>
  );
}

export default ProfileBeforeSession;
