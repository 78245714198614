import React, { useState, useEffect } from 'react';
import resourcesBanner from "../../assets/ResourcesBanner.png";
import "../../styles/TopSection.css";

function TopSection() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  return (
    <div className="resources-section" style={{ width: windowWidth, height: windowWidth * (window.innerWidth > 1030 ? 0.4 : window.innerWidth > 750 ? 0.7 : 2) }}>
      <img src={window.innerWidth < 750 ? null : window.innerWidth > 1030 ? resourcesBanner : null} alt="" className="resources-banner" />
      <div className="text-content-resources">
        <h1 style={{ fontWeight: "700" }}>How does it work?</h1>
        <p>
          Doro's database page collects{" "}
          <b>validated mental health questionnaires</b> that are publicly available and free to use. While Doro may suggest
          them, you can take them at any time. These assessments alone{" "}
          <b>do not confirm a mental health condition</b>, as a thorough
          diagnosis requires professional guidance. However, they can aid in{" "}
          <b>early symptom identification </b> and{" "}
          <b>foster informed discussions with healthcare providers</b>. Additionally, the data collected from these questionnaires will be used for research purposes and to enhance Doro’s functionality.

        </p>
      </div>
    </div>
  );
}

export default TopSection;
