import React, { useState }  from 'react';
import '../../styles/SubcriptionPopUp.css';
import close from '../../assets/close.svg';
import axiosInstance from "../../utils/axiosConfig";
import { useSelector } from "react-redux";
import { selectAuthToken } from "../../store/slices/authSlice";

const SubscriptionPopUp = ({ isOpen, closePopup }) => {
  const [selectedPackage, setSelectedPackage] = useState('ten-sessions');
  const token = useSelector(selectAuthToken);

  if (!isOpen) return null;

  const selectPackage = (packageId) => {
    setSelectedPackage(packageId);
  };

  const completePurchase = () => { 
    axiosInstance.post(`/checkout/create-session/${selectedPackage}`, '', {
      headers: {
        Authorization: `${token}`,
      },
      responseType: 'json',
    }).then(response => {
        const redirectUrl = response.data;
        window.location.href = redirectUrl;
    }).catch(error => {
        console.error('Error:', error);
    });
  };
  
  return (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="popup-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={closePopup}>
            <img src={close} alt="Close" />
        </button>
          <h2 className="popUpTitle" style={{color: "white", textAlign: "center", marginBottom: "30px", fontWeight: "900"}}>Choose Your Plan</h2>
          <div className="session-packages">
              <div 
                className={`session-card ${selectedPackage === 'ten-sessions' ? 'selected' : ''}`}
                style={{marginRight: "-20px"}}
                onClick={() => selectPackage('ten-sessions')}
              >
                  <h3 className="optionTitle" style={{color: "#4F5865", fontWeight: "800"}}>10 Sessions</h3>
                  <p><span className="price new-price">$30</span> <span className="price old-price">$60</span></p>
                  {window.innerWidth > 750 && 
                  <ul className="points" style={{color: "#4F5865", padding: "0px"}}>
                    <li><b>Long-term Support</b>: Ideal for ongoing challenges and daily support.</li>
                    <li><b>Deep Dive</b>: Engage in extensive journaling, thought exploration, and tackle serious challenges.</li>
                    <li><b>Personalized Learning</b>: Doro adapts to understand and support your unique needs over time.</li>
                  </ul>}
              </div>
              <div 
                className={`session-card ${selectedPackage === 'five-sessions' ? 'selected' : ''}`}
                onClick={() => selectPackage('five-sessions')}
              >
                  <h3 className="optionTitle" style={{color: "#4F5865", fontWeight: "800"}}>5 Sessions</h3>
                  <p><span className="price new-price">$20</span> <span className="price old-price">$35</span></p>
                  {window.innerWidth > 750 && 
                  <ul className="points" style={{color: "#4F5865", padding: "0px"}}>
                    <li><b>Short-term Focus</b>: Perfect for addressing specific emotional challenges promptly.</li>
                    <li><b>Targeted Support</b>: Concentrate on immediate issues with direct and effective interventions.</li>
                  </ul>}
              </div>
          </div>
          <button className="purchase-button" onClick={completePurchase}>Complete Your Purchase</button>
      </div>
    </div>
  );
};

export default SubscriptionPopUp;
