import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    white: '#ffffff',
    white80: '#ffffff80', //white with 80 opacity
    white50: '#ffffff50', //white with 50 opacity
    white16: '#ffffff16',
    hotPink: '#B8336A',
    lightGray:'#D4DCE9', //lightest
    lightGray2: '#F8FBFF',
    darkGray:'#CBD0DB',
    scrollbarC:'#484F59',
  },
  fontFamily: {
    primary:  'Inter, sans-serif',
    secondary: 'Karla, sans-serif',
  },
  fontWeight: {
    regular: 400,
    medium: 600,
    large: 700,
    bold: 800,
  },
  fontSize: {
    smaller: '16px',
    small: '18px',
    medium: '20px',
    large: '22px',
    larger: '24px',
    xLarge: '28px',
    xxLarge: '32px',
  },
});
export {theme};