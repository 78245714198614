export const questionnaireHeaderStyles = {
  position: 'relative',
  width: '100%',
  height: '0vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '40px',
};

export const headerStyles = {
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '29px',
  fontWeight: '800',
  color: '#484F59',
  textAlign: 'center',
  zIndex: 2,
  marginBottom: "-5%",
};

// Styles for the card container:
export const doroContainerStyles = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "10%",
  marginRight: "10%",
};

export const mobileDoroContainerStyles = {
  width: "100%",
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "0",
  margin: "0",
  overflowX: "auto",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  marginLeft: "0%",
  marginRight: "0%",

  // Hide scrollbar in Webkit browsers like Safari and Chrome
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

export const viewMoreButtonStyles = {
  display: 'block',
  margin: '50px auto',  // top and bottom margin is 20px, auto will center the button
  padding: '10px 40px', // padding for the button
  backgroundColor: '#E7F0ED',
  color: '#B8336A', // text color
  border: '1px solid #B8336A',
  borderRadius: '25px',
  cursor: 'pointer',
  fontSize: '16px',
  transition: 'background-color 0.5s ease, color 0.5s ease',
  fontWeight: '600',
};

export const viewButtonHover = {
  backgroundColor: "#B8336A",
  border: "1px solid #B8336A",
  color: "#FFFFFF",
}

export const getStartedButtonStyles = {
  width: "18%",
  textAlign: "center",
  display: "block",
  margin: "30px auto", // top and bottom margin is 20px, auto will center the button
  marginTop: "1%",
  marginBottom: "4%",
  padding: "10px 30px", // padding for the button
  backgroundColor: "#C9D4F84D",
  color: "#484F59", // text color
  borderRadius: "25px",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: "600",
  border: "1px solid transparent",
};

export const mobileGetStartedButtonStyle = {
  width: "50%"
}

export const getStartedButtonHoverStyle = {
  backgroundColor: "transparent",
  border: "1px solid #484F59",
  color: "#484F59",
};
