import React, { useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Drawer,
  IconButton,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logoImage from '../assets/logo.svg';
import darkLogoImage from '../assets/logoDark.svg';
import phone from '../assets/icons/phone.png';
import '../styles/NavBar.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoggedIn, logout } from '../store/slices/authSlice';
import { useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import useWindowSizeListener from '../hooks/useWindowSizeListener';
import {LayoutContext} from '../context/LayoutContext';
import { theme } from '../layout/Theme';

function Navbar () {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const {isChatStarted, setIsChatStarted, themeMode} = useContext(LayoutContext);
  const {windowSize} = useWindowSizeListener();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showMenuIcon, setShowMenuIcon] = useState(true);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth
      .signOut()
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
  };

  const handleExternalLink = () => {
    window.open('https://findahelpline.com/', '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    (isChatStarted && windowSize.deviceType !== 'large') ?
      setShowMenuIcon(false) : setShowMenuIcon(true);
  }, [windowSize, isChatStarted]);

  const handDoroImgClick = () => setIsChatStarted(false);

  return (
    <AppBar position='fixed' elevation={1} className='navbar'>
      <Toolbar
        style={{
          justifyContent: 'space-between',
          marginLeft: '0.5%',
          marginRight: '0.5%',
        }}
      >
        <Link to="/" style={{ flexGrow: 1 }} onClick={handDoroImgClick}>
          <img
            src={(themeMode==='dark' || windowSize.deviceType === 'large') ? logoImage : darkLogoImage}
            alt='Doro'
            style={{ height: '50px', width: 'auto', marginTop: '5px' }}
          />
        </Link>
        {showMenuIcon && (<IconButton
          edge='start'
          aria-label='menu'
          disableRipple={false}
          sx={{
            display: { xs: 'block', lg: 'none' },
            fontSize: { xs: '2rem', sm: '2.5rem' },
          }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon className='hamburgerIcon' sx={{ fontSize: 'inherit', color: themeMode==='dark' ? 'white' : theme.palette.scrollbarC,}} />
        </IconButton>)}
        <Drawer
          anchor='right'
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          ModalProps={{
            BackdropProps: {
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.15)',
              },
            },
          }}
          PaperProps={{
            style: {
              boxShadow: '2px 0px 45px rgba(0, 0, 0, 0.26)', // Adjust the shadow's direction, blur, and color
            },
          }}
        >
          <div
            role='presentation'
            className='drawerBox'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <IconButton
              aria-label='close'
              onClick={toggleDrawer(false)}
              sx={{
                position: 'absolute', // Positions the close button at the top-right corner of the drawer
                right: '7.5%',
                top: 8,
                fontSize: { xs: '2rem', sm: '2.5rem' },
                color: 'white',
              }}
            >
              <CloseIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
            <Link className='option' to='/'>
              Home
            </Link>
            <Divider className='divider' />
            <Link
              className='option'
              to={isLoggedIn ? '/doro-test-start' : '/get-started'}
            >
              DORO
            </Link>
            <Divider className='divider' />
            <Link className='option' to='/resources'>
              Questionnaires
            </Link>
            <Divider className='divider' />
            {isLoggedIn ? (
              <Link className='option' to='/profile'>
                Profile
              </Link>
            ) : (
              <Link className='option' to='/get-started'>
                Get Started
              </Link>
            )}
            {isLoggedIn && <Divider className='divider' />}
            {isLoggedIn && (
              <Link className='option' to='/' onClick={handleLogout}>
                Logout
              </Link>
            )}
            <Divider className='divider' />
            <Typography
              component={'span'}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 5,
                cursor: 'pointer',
                color: 'white',
                marginTop: '10px',
              }}
              onClick={handleExternalLink}
            >
              <img
                src={phone}
                alt='Help Line'
                style={{ height: '25px', width: 'auto', marginRight: '8px' }}
              />
              <div>
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Help is available
                </span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '14px',
                    marginTop: '-5px',
                  }}
                >
                  Speak with someone today
                </span>
              </div>
            </Typography>
          </div>
        </Drawer>
        {location.pathname === '/doro-test-start' && (
          <Typography
            component={'span'}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 5,
              cursor: 'pointer',
            }}
            sx={{
              '@media (max-width: 1080px)': {
                display: 'none !important;',
              },
            }}
            onClick={handleExternalLink}
          >
            <img
              src={phone}
              alt='Help Line'
              style={{ height: '25px', width: 'auto', marginRight: '8px' }}
            />
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                Help is available
              </span>
              <span
                style={{
                  display: 'block',
                  fontSize: '14px',
                  marginTop: '-5px',
                }}
              >
                Speak with someone today
              </span>
            </div>
          </Typography>
        )}
        <Button
          color='inherit'
          className='buttonStyle'
          sx={{
            marginRight: '3%',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#CBD0DB',
            },
          }}
          component={Link}
          to='/'
        >
          Home
        </Button>
        <Button
          color='inherit'
          className='buttonStyle'
          sx={{
            marginRight: '3%',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#CBD0DB',
            },
          }}
          component={Link}
          to={isLoggedIn ? '/doro-test-start' : '/get-started'}
        >
          DORO
        </Button>
        <Button
          color='inherit'
          className='buttonStyle'
          sx={{
            marginRight: '3%',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#CBD0DB',
            },
          }}
          component={Link}
          to='/resources'
        >
          Questionnaires
        </Button>
        {/* Conditionally render based on isLoggedIn */}
        {isLoggedIn ? (
          <Button
            color='inherit'
            className='buttonStyle'
            variant='outlined'
            sx={{
              borderRadius: '25px',
              marginRight: '3%',
              '&:hover, &:active': {
                backgroundColor: 'transparent !important',
                color: '#CBD0DB !important',
              },
            }}
            component={Link}
            to='/profile'
          >
            Profile
          </Button>
        ) : (
          <Button
            color='inherit'
            className='buttonStyle'
            variant='outlined'
            sx={{
              borderRadius: '25px',
              '&:hover, &:active': {
                backgroundColor: 'transparent !important',
                color: '#CBD0DB !important',
              },
            }}
            component={Link}
            to='/get-started'
          >
            Get Started
          </Button>
        )}
        {isLoggedIn && (
          <Button
            color='inherit'
            className='buttonStyle'
            variant='outlined'
            sx={{
              borderRadius: '25px',
              '&:hover, &:active': {
                backgroundColor: 'transparent !important',
                color: '#CBD0DB !important',
              },
            }}
            component={Link}
            to='/'
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
