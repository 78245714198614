import React from 'react';
import {DoroChatProvider} from '../context/DoroChatContext';
import DoroChat from '../components/dorochat/DoroChat';

const DoroChatView = () => {
    return (
        <DoroChatProvider>
            <DoroChat />
        </DoroChatProvider>
    );
};

export default DoroChatView;