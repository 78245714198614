import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { selectIsLoggedIn } from "../../store/slices/authSlice";
import {
  missionStyles,
  missionTitleStyles,
  missionContentStyles,
  missionButton,
  missionButtonHover,
} from "../../styles/Mission";

function Mission() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [hover, setHover] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tooltipStyles = {
    color: 'white',
    fontSize: '14px',
    borderRadius: '8px',
    padding: '10px',
    fontWeight: 'bold',
  };

  return (
    <div
      style={{
        ...missionStyles,
        ...(isMobile ? { marginLeft: "5%", marginRight: "5%" } : {}),
      }}
    >
      <h2 style={missionTitleStyles}>Our Mission</h2>
      <p style={missionContentStyles}>
        Doro's mission is to transform mental health care by identifying and
        addressing mental health conditions at their{" "}
        <Tooltip
          title={<Typography sx={tooltipStyles}>An early stage with mild symptoms, not reaching the full severity or criteria of a diagnosable disorder.</Typography>}
          sx={{ fontSize: 'inherit' }}
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => '#484F59',
                }
              }
            }
          }}
        >
          <strong style={{ fontSize: "22px" }}>subclinical stage</strong>
        </Tooltip>. Our aim is to reach{" "}
        <Tooltip
          title={<Typography sx={tooltipStyles}>Individuals highly susceptible to developing a mental health condition due to exposure to factors like stress.</Typography>}
          sx={{ fontSize: 'inherit' }}
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => '#484F59',
                }
              }
            }
          }}
        >
          <strong style={{ fontSize: "22px" }}>individuals at risk</strong>
          </Tooltip>{" "}
          early, preventing the escalation of mental health issues and the profound
          consequences of fully developed disorders. We strive to make{" "}
          <Tooltip
          title={<Typography sx={tooltipStyles}>Taking action for mental health care before issues arise.</Typography>}
          sx={{ fontSize: 'inherit' }}
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => '#484F59',
                }
              }
            }
          }}
        >
          <strong style={{ fontSize: "22px" }}>proactive mental health care</strong>
        </Tooltip>{" "}
        accessible.
      </p>
      <Button
        style={{
          ...missionButton,
          ...(hover ? missionButtonHover : {}),
          textTransform: "none",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        component={Link}
        to={isLoggedIn ? "/doro-test-start" : "/get-started"}
      >
        Doro Session
      </Button>
    </div>
  );
}

export default Mission;
