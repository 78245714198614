import { getAuth } from 'firebase/auth';
import firebase from "firebase/compat/app";

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCeuGlKa4ZTQhEOTlTn-MoiRKkHWd0PicE",
    authDomain: "razroze-doro.firebaseapp.com",
    projectId: "razroze-doro",
    storageBucket: "razroze-doro.appspot.com",
    messagingSenderId: "744254344766",
    appId: "1:744254344766:web:fcf729f09aaeeaeea34b6f",
    measurementId: "G-GRMF60R0YM",
  };
  
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  const auth = getAuth(app);

  export { auth };